import React, { useContext } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { HTMLContent } from "../components/Content";
import SplitPageContent from "../components/SplitPageContent/SplitPageContent";
import PageSection from "../components/PageSection/PageSection";
import withLayoutAndData from "./generic/withLayoutAndData";
import NewsletterForm from "../components/NewsletterForm/NewsletterForm";
import AdSenseAd from "../components/AdNetworks/AdSenseAd";
import PreviewContext from "../components/Layout/PreviewContext";

//TODO: !!!! MOVE TO SHARED COMPONENT !!!!
const PageContent = styled(HTMLContent)`

  h2{
    margin-top: 20px;
  }
`;

export const AboutPage = ({ page }) => {
	const isPreview = useContext(PreviewContext);

	return <>
		<SplitPageContent
			title={page.title}
			image={page.image}
			imageYPosition={page.imageYPosition}
			mainColor={"#96a7a1"}
			sections={page.sections}>
			<PageSection>
				<PageContent content={page.content}/>
			</PageSection>
			<PageSection>
				<NewsletterForm/>
			</PageSection>
		</SplitPageContent>
		{!isPreview &&
		<AdSenseAd slot="3928202188" format="auto" fullWidthResponsive/>}
	</>;
};

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      fields {
        slug
      }
      frontmatter {
        title
        heading
        sections
        imageYPosition
      }
      ...RemoteBannerImage
    }
  }`;

export default withLayoutAndData(
	({ data }) => ({
		page: {
			...data.markdownRemark.frontmatter,
			...data.markdownRemark.fields,
			content: data.markdownRemark.html,
		}
	})
)(AboutPage);
